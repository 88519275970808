import { compose } from 'redux';
import { ContentLoading } from 'src/components/ContentLoading';
import { Login } from 'src/components/Login';
import { withRouter } from 'src/routes/withRouter';
import { identifyUser } from 'src/utilities';
import React from 'react';

import { connectToState } from './withCurrentUser.connect';
import { WithCurrentUserProps, WithCurrentUserState } from './withCurrentUser.types';

export function withCurrentUserBase(WrappedComponent: React.ComponentType<any>) {
    class WithCurrentUserBaseClass extends React.Component<
        WithCurrentUserProps,
        WithCurrentUserState
    > {
        constructor(props: WithCurrentUserProps) {
            super(props);
            this.state = {
                isInitialLoadFired: false,
            };
        }

        componentDidMount() {
            const { loadCurrentUser, currentUserID } = this.props;
            if (!currentUserID) {
                loadCurrentUser();
            }
        }

        componentDidUpdate(
            _prevProps: Readonly<WithCurrentUserProps>,
            prevState: Readonly<WithCurrentUserState>,
        ) {
            const {
                loadSeequentCloudIntegration,
                loadExportTemplates,
                loadSubscription,
                loadAccountPreferences,
                loadUserAccounts,
                currentUserID,
            } = this.props;
            if (currentUserID) {
                // Omit sending an ID to analytics on the off-chance it is an invited user.
                // From docs:
                // > Call identify when you know the identity of the current user, typically after login or signup.
                // > We recommend against using identify for anonymous visitors to your site.
                identifyUser(currentUserID);
            }
            if (currentUserID && !prevState.isInitialLoadFired && !this.state.isInitialLoadFired) {
                this.setState({ ...prevState, isInitialLoadFired: true });
                loadSubscription();
                loadAccountPreferences();
                loadSeequentCloudIntegration();
                loadExportTemplates();
                loadUserAccounts();
            }
        }

        render() {
            if (!this.props.isSubscriptionDataStateComplete) {
                if (this.props.isFailedWithAuthError) {
                    return <Login returnUrl={this.props.location.pathname} />;
                }
                return (
                    <ContentLoading
                        isFailed={
                            this.props.isSubscriptionDataStateFailed ||
                            this.props.isCurrentUserFailed
                        }
                        skeletonLoading
                        fullAppSkeleton
                    />
                );
            }
            if (!this.props.isEAP) {
                return <Login error="403" />;
            }
            return <WrappedComponent {...(this.props as any)} />;
        }
    }

    return WithCurrentUserBaseClass;
}

export const withProps = compose(
    connectToState, // This has to come first, so the `loadCurrentUser` action is present in the props.
    withRouter,
    withCurrentUserBase,
);
export const withCurrentUser = (WrappedComponent: React.ComponentType) =>
    withProps(WrappedComponent);
